export interface Configuration {
    apiUrl: string,
}

export interface DeviceList {
    totalRows: number,
    items: Array<{
        family: string,
        uid: string,
        model: string,
        firmware: string,
    }>,
}

export interface LiveLink {
    playURL: string,
    expiredIn: number,
}

export async function getConfiguration(signal?: AbortSignal) {
    let response: Response | undefined = undefined;

    try {
        response = await fetch(
            "config.json",
            {
                signal: signal,
            });
    }
    catch (error) {
        if ((error as Error)?.name !== "AbortError") {
            throw error;
        }

        return;
    }

    if (response.ok) {
        return await (response.json() as Promise<Configuration | undefined>);
    }
}

export async function listDevices(apiUrl: string, signal?: AbortSignal) {
    let response: Response | undefined = undefined;

    try {
        response = await fetch(
            `${apiUrl}/device/list`,
            {
                signal: signal,
            });
    }
    catch (error) {
        if ((error as Error)?.name !== "AbortError") {
            throw error;
        }

        return;
    }

    if (response.ok) {
        return await (response.json() as Promise<DeviceList | undefined>);
    }
}

export async function getLive(apiUrl: string, uid: string, signal?: AbortSignal) {
    let response: Response | undefined = undefined;

    try {
        response = await fetch(
            `${apiUrl}/device/${uid}/live`,
            {
                signal: signal,
            });
    }
    catch (error) {
        if ((error as Error)?.name !== "AbortError") {
            throw error;
        }

        return;
    }

    if (response.ok) {
        return await (response.json() as Promise<LiveLink | undefined>);
    }
}

export async function getMjpegChunks(apiUrl: string, uid: string, mediaType: "rtsp" | "http", signal?: AbortSignal) {
    let response: Response | undefined = undefined;

    try {
        response = await fetch(
            `${apiUrl}/media/${uid}/mjpeg/${mediaType}`,
            {
                signal: signal,
            });
    }
    catch (error) {
        if ((error as Error)?.name !== "AbortError") {
            throw error;
        }

        return;
    }

    if (!response.ok) {
        throw new Error(response.statusText);
    }

    if (!response.body) {
        return;
    }

    const boundaryMatch = response.headers.get("Content-Type")?.match(new RegExp("^multipart/x-mixed-replace; boundary=([^\\s;]+)$", "im"));
    if (boundaryMatch && boundaryMatch[1]) {
        return { stream: response.body, boundary: boundaryMatch[1] };
    }
}
